import { PimInformation, Translation } from '@al-ko/types';
import { Observable } from 'rxjs';
import { ErrorDescription } from '../domain/error-description.type';

export abstract class IPimRepository {
  abstract getProductInformation(
    articleNumber: string,
  ): Observable<PimInformation>;
  abstract getProductInformations(
    articleNumbers: string[],
  ): Observable<{ [articleNumber: string]: PimInformation }>;
  abstract getSparepartsOfProduct(
    articleNumber: string,
  ): Observable<PimInformation[]>;
  abstract getErrorDescriptions(): Observable<{
    [errorCode: number]: ErrorDescription;
  }>;
  abstract getTranslations(
    translationPool: string,
  ): Observable<{ [key: string]: string }>;
}
