import { HistoryData, PimInformation, Thing, ThingType } from '@al-ko/types';
import {
  BehaviorSubject,
  map,
  Observable,
  of,
  ReplaySubject,
  startWith,
  Subscription,
  switchMap,
} from 'rxjs';
import { IDeviceInteractor } from '../contracts/idevice.interactor';
import { GetAllDevicesUsecase } from '../../../core/usecases/device-usecases/get-all-devices.usecase';
import { GetDeviceByIdUsecase } from '../../../core/usecases/device-usecases/get-device-by-id.usercase';
import { Injectable, signal, Signal } from '@angular/core';
import { ThingState } from '../../../core/domain/thingstate.type';
import { SubscribeToDeviceUsecase } from '../../../core/usecases/device-usecases/subscribe-to-device.usecase';
import { IDeviceConnectionRepository } from '../../../core/repositories/device-connection.repository';
import { GetMetaDataOfDeviceUsecase } from '../../../core/usecases/get-metadata-of-device.usecase';
import { MetaDataModel } from '../../../core/domain/metadata.type';
import { GetProductInfoUsecase } from '../../../core/usecases/pim-usecases/get-product-info.usecase';
import { GetHistoryDataUsecase } from '../../../core/usecases/history-usecases/get-history-data.usecase';
import { AlkoThing } from 'src/app/core/domain/thing.type';

@Injectable({ providedIn: 'root' })
export class DeviceInteractor extends IDeviceInteractor {
  override getDeviceSignal(thingName: string): Signal<AlkoThing> {
    // fake some data chagnes
    // getRandomNumberObservable().subscribe((x) => {
    //   if (this.devices[thingName]) {
    //     this.devices[thingName].thing.update((current) => {
    //       const state = { ...current.state, ...{ batteryLevel: x } };
    //       return { ...current, ...{ state: state } };
    //     });
    //   }
    // });

    //  if device has shit
    if (this.devices[thingName]) {
      // this.devices[thingName].thing.update((current) => {
      //   return { ...current, ...{ thingState: { batteryLevel: 0 } } };
      // });
      return this.devices[thingName].thing;
    }

    this.devices[thingName] = {
      sub: undefined,
      thing: signal(demoAlkoThing),
    };
    return this.devices[thingName].thing;
  }
  // private historyBook: { [thingName: string]: { [attributeName: string]: HistoryData[] }} = {};
  private historyBook: {
    [thingName: string]: {
      [attributeName: string]: ReplaySubject<HistoryData[]>;
    };
  } = {};
  private subscriptions: Subscription[] = [];

  constructor(
    private getAllDevicesUsecase: GetAllDevicesUsecase,
    private getDeviceByIdUsecase: GetDeviceByIdUsecase,
    private connectionRepo: IDeviceConnectionRepository,
    private getMetadataUsecase: GetMetaDataOfDeviceUsecase,
    private getProductInfoUsecase: GetProductInfoUsecase,
    private getHistoryDataUsecase: GetHistoryDataUsecase,
    // private subscribeToDeviceUsecase: SubscribeToDeviceUsecase,
  ) {
    super();
  }

  private mergeCurrentStateWithUpdate(
    current: AlkoThing,
    update: AlkoThing,
  ): AlkoThing {
    Object.keys(update).forEach((key) =>
      update[key] === undefined ? delete update[key] : {},
    );
    return { ...current, ...update };
  }

  override getDeviceList(): Observable<AlkoThing[]> {
    return this.getAllDevicesUsecase.execute().pipe(
      map((x) => {
        for (const thing of x) {
          if (this.devices[thing.id]) {
            this.devices[thing.id].thing.update((current) => {
              return this.mergeCurrentStateWithUpdate(current, thing);
            });
          }
          this.devices[thing.id] = {
            sub: undefined,
            thing: signal(thing),
          };
        }
        return x;
      }),
    );
  }

  override getDevice(thingName: string): Observable<AlkoThing> {
    const o = this.getDeviceByIdUsecase.execute(thingName);
    return o;
  }

  override subscribeToDevice(thingName: string): Observable<any> {
    return this.connectionRepo.subscribe(thingName);
  }
  override unsubscribeToDevice(thingName: string): void {
    return this.connectionRepo.unsubscribe(thingName);
  }

  override getDeviceMetadata(thingName: string): Observable<MetaDataModel[]> {
    return this.getMetadataUsecase.execute(thingName);
  }
  override createDeviceMetadata(thingName: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  override getProductInformation(
    articleNumber: string,
  ): Observable<PimInformation> {
    return this.getProductInfoUsecase.execute(articleNumber);
  }

  override getHistoryData(
    thingName: string,
    attributeName: string,
  ): Observable<HistoryData[]> {
    if (!this.historyBook[thingName]) {
      this.historyBook[thingName] = {};
    }

    if (!this.historyBook[thingName][attributeName]) {
      this.historyBook[thingName][attributeName] = new ReplaySubject<
        HistoryData[]
      >(1);
      const sub = this.getHistoryDataUsecase
        .execute({
          attributeName: attributeName,
          thingName: thingName,
        })
        .subscribe((historyData) =>
          this.historyBook[thingName][attributeName].next(historyData),
        );
      this.subscriptions.push(sub);
    }
    // return of(this.historyBook[thingName][attributeName]);
    return this.historyBook[thingName][attributeName].asObservable();
  }

  override refreshDeviceState(thingName: string): void {
    console.log('refreshingDeviceState');
    this.getDeviceByIdUsecase.execute(thingName).subscribe((t) => {
      if (this.devices[thingName] == undefined) {
        console.log(
          '[DeviceInteractor].[refreshDeviceState] create new signal with:',
          t,
        );
        this.devices[thingName] = {
          thing: signal(t),
          sub: undefined,
        };
      } else {
        console.log(
          '[DeviceInteractor].[refreshDeviceState] set existing signal:',
          t,
        );
        this.devices[thingName].thing.set(t);
      }
    });
  }
}

const demoAlkoThing: AlkoThing = {
  id: '7f0763440876b365f5e70d2e7a16e0bca6deb5ea',
  type: ThingType.robolinho,
  attributes: {
    thingName: '7f0763440876b365f5e70d2e7a16e0bca6deb5ea',
    thingType: ThingType.robolinho,
    thingModel: 'Robolinho520',
    articleNumber: '127695',
    serialNumber: '3B24-082457',
    firmwareVersion: '2404A-SW',
    hardwareVersion: '1D',
    productionDate: '20230301',
    betatester: 'True',
    serialNumberMain: '3B24-082457',
    hardwareVersionMain: '1D',
    firmwareMain: '2404A-SW',
    firmwareMainLocalization: 'SW',
    articleNumberWifi: '495146',
    serialNumberWifi: '012319DC12BE4A80EE',
    hardwareVersionWifi: '1D',
    firmwareWifi: '1.9.B',
    firmwareWifiDriver: 'V19.6.1',
    fotaMode: 'off',
  },
  accessInformation: {
    accessId: '59cd0aa6-549d-461b-8039-c23f5b15e611',
    thingName: '7f0763440876b365f5e70d2e7a16e0bca6deb5ea',
    userId: '0008020010',
    idpAccountId: '55261bbe-21e3-4d10-b381-57a8337e4fe8',
    userEmail: 'thomas.echerer@al-ko.com',
    accessAlias: 'Schulungszentrum',
    accessAdmin: true,
    accessCreated: '2024-05-29T07:33:22.853Z',
  },
};

const demoThing: Thing = {
  thingName: '7f0763440876b365f5e70d2e7a16e0bca6deb5ea',
  thingType: ThingType.robolinho,
  thingAttributes: {
    thingName: '7f0763440876b365f5e70d2e7a16e0bca6deb5ea',
    thingType: ThingType.robolinho,
    thingModel: 'Robolinho520',
    articleNumber: '127695',
    serialNumber: '3B24-082457',
    firmwareVersion: '2404A-SW',
    hardwareVersion: '1D',
    productionDate: '20230301',
    betatester: 'True',
    serialNumberMain: '3B24-082457',
    hardwareVersionMain: '1D',
    firmwareMain: '2404A-SW',
    firmwareMainLocalization: 'SW',
    articleNumberWifi: '495146',
    serialNumberWifi: '012319DC12BE4A80EE',
    hardwareVersionWifi: '1D',
    firmwareWifi: '1.9.B',
    firmwareWifiDriver: 'V19.6.1',
    fotaMode: 'off',
  },
  accessInformation: {
    accessId: '59cd0aa6-549d-461b-8039-c23f5b15e611',
    thingName: '7f0763440876b365f5e70d2e7a16e0bca6deb5ea',
    userId: '0008020010',
    idpAccountId: '55261bbe-21e3-4d10-b381-57a8337e4fe8',
    userEmail: 'thomas.echerer@al-ko.com',
    accessAlias: 'Schulungszentrum',
    accessAdmin: true,
    accessCreated: '2024-05-29T07:33:22.853Z',
  },
};

function getRandomNumberObservable(): Observable<number> {
  return new Observable<number>((subscriber) => {
    // Emit the first random number immediately
    emitRandomNumber(subscriber);

    // Set up an interval of 2000ms (2 seconds) to emit random numbers
    const intervalId = setInterval(() => {
      emitRandomNumber(subscriber);
    }, 2000);

    // Teardown logic to clear the interval when the Observable is unsubscribed
    return () => clearInterval(intervalId);
  }).pipe(
    map((value) => Math.round(value)), // Ensure the number is rounded to an integer between 0 and 100
  );
}

function emitRandomNumber(subscriber: any): void {
  const randomNumber = Math.random() * 100; // Generate a random number between 0 and 100
  subscriber.next(randomNumber);
}
