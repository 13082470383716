import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IHistoryDataRepository } from '../../repositories/history-data.repository';
import { HistoryData } from '@al-ko/types';
import { UseCase } from '../../base/use-case';

type Input = {
  thingName: string;
  attributeName: string;
};

@Injectable({
  providedIn: 'root',
})
export class GetHistoryDataUsecase implements UseCase<Input, HistoryData[]> {
  constructor(private historyRepo: IHistoryDataRepository) {}

  execute(input: Input): Observable<HistoryData[]> {
    return this.historyRepo
      .getHistoryData(input.thingName, input.attributeName, undefined)
      .pipe(map((x) => x.data));
  }
}
