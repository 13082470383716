import { Injectable } from '@angular/core';
import { UseCase } from '../../base/use-case';
import { Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { Thing } from '@al-ko/types';
import { IDeviceRepository } from '../../repositories/device.repository';
import { IStorageRepository } from '../../repositories/storage.repository';
import { AlkoThing } from '../../domain/thing.type';

@Injectable({
  providedIn: 'root',
})
export class GetAllDevicesUsecase implements UseCase<void, AlkoThing[]> {
  private dataSubect = new ReplaySubject<AlkoThing[]>(1);
  private sub: Subscription;

  constructor(
    private deviceRepository: IDeviceRepository,
    private storage: IStorageRepository,
  ) {}

  execute(): Observable<AlkoThing[]> {
    const key = 'ts';
    //  get stored value
    const storedValue = this.storage.getData<AlkoThing[]>(key);
    if (storedValue) {
      this.dataSubect.next(storedValue);
    }
    //  request iot data
    this.sub = this.deviceRepository.getAllDevices().subscribe((data) => {
      if (storedValue) {
        this.storage.saveData(key, this.mergeData(storedValue, data));
      } else {
        this.storage.saveData(key, data);
      }
      this.dataSubect.next(data);
    });

    //  return pim-data
    return this.dataSubect.asObservable();
  }

  private mergeData(
    cachedData: AlkoThing[],
    newData: AlkoThing[],
  ): AlkoThing[] {
    newthingsLoop: for (let i = 0; i < newData.length; i++) {
      cachedthingsLoop: for (let j = 0; j < cachedData.length; j++) {
        if (newData[i].id == cachedData[j].id) {
          newData[i] = { ...cachedData[j], ...newData[i] };
          continue newthingsLoop;
        }
      }
    }
    return newData;
  }
}
