import { Injectable } from '@angular/core';
import { UseCase } from '../../base/use-case';
import { map, Observable, of, ReplaySubject } from 'rxjs';
import { PimInformation, Thing } from '@al-ko/types';
import { IPimRepository } from '../../repositories/pim.repository';
import { IStorageRepository } from '../../repositories/storage.repository';

@Injectable({
  providedIn: 'root',
})
export class GetProductInfoUsecase implements UseCase<string, PimInformation> {
  private dataSubect = new ReplaySubject<PimInformation>(1);
  private sub;

  constructor(
    private pimRepository: IPimRepository,
    private storage: IStorageRepository,
  ) {}

  execute(articleNumber: string): Observable<PimInformation> {
    const key = `pim.p.${articleNumber}`;
    //  get stored value
    const storedValue = this.storage.getData<PimInformation>(key);
    if (storedValue) {
      //  is value stil valid / not expired
      if (storedValue['isValid']) {
        //  return stored value
        return of(storedValue);
      } else {
        this.dataSubect.next(storedValue);
      }
    }
    //  request pim data
    this.sub = this.pimRepository
      .getProductInformation(articleNumber)
      .subscribe((data) => {
        console.log('[GetProductInfoUsecase] returing new data: ', data);
        this.storage.saveData(key, data);
        this.dataSubect.next(data);
      });

    //  return pim-data
    return this.dataSubect.asObservable();
  }
}
