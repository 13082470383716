import { HistoryData } from '@al-ko/types';
import { Observable } from 'rxjs';

export abstract class IHistoryDataRepository {
  abstract getHistoryData(
    thingName: string,
    attributeName: string,
    lastEvalKey: string,
    timestampFrom?: number,
  ): Observable<{ lastEvalKey: string; data: HistoryData[] }>;
}
