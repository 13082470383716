import { Injectable } from '@angular/core';
import { UseCase } from '../../base/use-case';
import { map, Observable, ReplaySubject } from 'rxjs';
import { Thing } from '@al-ko/types';
import { IDeviceRepository } from '../../repositories/device.repository';
import { IStorageRepository } from '../../repositories/storage.repository';
import { AlkoThing } from '../../domain/thing.type';

@Injectable({
  providedIn: 'root',
})
export class GetDeviceByIdUsecase implements UseCase<string, AlkoThing> {
  private thingsKey = 'ts';
  private dataSubect = new ReplaySubject<AlkoThing>(1);
  private sub;

  constructor(
    private deviceRepository: IDeviceRepository,
    private storage: IStorageRepository,
  ) {}

  execute(thingName: string): Observable<AlkoThing> {
    const cachedThing = this.getCachedThing(thingName);
    if (cachedThing) {
      this.dataSubect.next(cachedThing);
    }

    this.sub = this.deviceRepository
      .getDeviceById(thingName)
      .subscribe((thing) => {
        this.udpateCachedData(thingName, thing);
        this.dataSubect.next(thing);
      });

    return this.dataSubect.asObservable();
  }

  private getCachedThing(thingName: string): AlkoThing | undefined {
    const cachedData = this.storage.getData<AlkoThing[]>(this.thingsKey);
    const max = cachedData.length;
    for (let i = 0; i < max; i++) {
      if (cachedData[i].id == thingName) {
        return cachedData[i];
      }
    }
    return undefined;
  }

  private udpateCachedData(thingName: string, data: AlkoThing): void {
    const cachedData = this.storage.getData<AlkoThing[]>(this.thingsKey);
    const max = cachedData.length;
    for (let i = 0; i < max; i++) {
      if (cachedData[i].id == thingName) {
        cachedData[i] = data;
        this.storage.saveData(this.thingsKey, cachedData);
        return;
      }
    }

    cachedData.push(data);
    this.storage.saveData(this.thingsKey, cachedData);
  }
}
