import { HistoryData, PimInformation, Thing } from '@al-ko/types';
import { Injectable, Signal, WritableSignal } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ThingMapper } from '../../mappers/thing.mapper';
import { MetaDataModel } from '../../../core/domain/metadata.type';
import { AlkoThing } from 'src/app/core/domain/thing.type';
// import { Result } from './../../../core/types/types';
// import { BookmarkRequest } from 'src/app/data/requests/bookmark.request';

@Injectable({ providedIn: 'root' })
export abstract class IDeviceInteractor {
  public devices: {
    [thingName: string]: {
      thing: WritableSignal<AlkoThing>;
      sub: Subscription;
    };
  } = {};

  abstract getDeviceSignal(thingName: string): Signal<AlkoThing>;
  abstract refreshDeviceState(thingName: string): void;

  //  OLD
  abstract getDeviceList(): Observable<AlkoThing[]>;
  abstract getDevice(thingName: string): Observable<AlkoThing>;

  //  OTHERS
  abstract subscribeToDevice(thingName: string): Observable<any>;
  abstract unsubscribeToDevice(thingName: string): void;
  abstract getDeviceMetadata(thingName: string): Observable<MetaDataModel[]>; //  is observeable the correct response here? or should it better be a promise?
  abstract createDeviceMetadata(thingName: string): Observable<any>; //  is observeable the correct response here? or should it better be a promise?
  abstract getHistoryData(
    thingName: string,
    attributeName: string,
  ): Observable<HistoryData[]>;

  abstract getProductInformation( //  is observeable the correct response here? or should it better be a promise?
    articleNumber: string,
  ): Observable<PimInformation>;
}
