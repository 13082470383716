import { PimInformation, Thing } from '@al-ko/types';
import { Observable } from 'rxjs';
import { ThingState } from '../domain/thingstate.type';
import { FotaStatus } from '../domain/fota-status.enum';
import { AlkoThing } from '../domain/thing.type';

export abstract class IDeviceRepository {
  abstract getAllDevices(): Observable<AlkoThing[]>;
  abstract getDeviceById(thingName: string): Observable<AlkoThing>;
  abstract getReportedStateOfDevice(thingName: string): Observable<ThingState>;
  abstract setDesiredState(
    thingName: string,
    desiredState: any,
  ): Observable<ThingState>;
  abstract getFotaStatus(
    thingName: string,
  ): Observable<{ fotaStatus: FotaStatus; targetVersion: string }>;
}
